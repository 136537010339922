import React, { useCallback, useEffect, useState } from 'react';
import Alert from '../../components/alert';
import clsx from 'clsx';
import useHttpClient from '../../hooks/useHttpClient';
import { Box, Container, makeStyles, Paper, Typography, useTheme } from '@material-ui/core';
import Loader from '../../components/loader';
import { useStyles } from '../../hooks/useStyles';
import BountyCards from '../../components/bountyCards';
import FormModal from '../../components/forms/formModal';
import CreateBountyForm from '../../components/forms/createBountyForm';
import DonateBountyForm from '../../components/forms/donateBountyForm';
import CompleteBountyForm from '../../components/forms/completeBountyForm';
import { HTTP_ACTION } from '../../utils/constants';
import { useOktaAuth } from '@okta/okta-react';

const useLocalStyles = makeStyles((theme) => ({
  infoPaper: { paddingTop: '1rem' },
}));

const BountiesPage = () => {
  const classes = useLocalStyles();
  const theme = useTheme();
  const globalStyles = useStyles();
  const { authState } = useOktaAuth();

  const [createIsOpen, setCreateIsOpen] = useState(false);
  const [completeIsOpen, setCompleteIsOpen] = useState(false);
  const [donateIsOpen, setDonateIsOpen] = useState(false);
  const [selectedBounty, setSelectedBounty] = useState();

  const [bounties, setBounties] = useState([]);

  const {
    httpRequest: getBounties,
    data: bountyData,
    loading: loadingBounties,
    error: getBountiesError,
  } = useHttpClient({
    url: `/bounty`,
    initialRequest: true,
  });
  const {
    httpRequest: getUser,
    data: userData,
    loading: loadingUser,
    error: userError,
  } = useHttpClient({
    url: `/users?email=${authState?.idToken?.claims?.email}`,
    initialRequest: true,
  });
  const {
    data: allUsersData,
    loading: loadingAllUsers,
    error: allUsersError,
  } = useHttpClient({
    url: `/users`,
    initialRequest: true,
  });

  const {
    httpRequest: createBountyRequest,
    loading: creatingBounty,
    error: createBountyError,
  } = useHttpClient({
    url: `/bounty`,
    httpAction: HTTP_ACTION.POST,
  });
  const {
    httpRequest: donateBountyRequest,
    loading: donatingBounty,
    error: donateBountyError,
  } = useHttpClient({
    httpAction: HTTP_ACTION.POST,
  });
  const {
    httpRequest: completeBountyRequest,
    loading: completingBounty,
    error: completeBountyError,
  } = useHttpClient({
    httpAction: HTTP_ACTION.POST,
  });

  // calculate bounty value for each bounty
  useEffect(() => {
    if (allUsersData && bountyData) {
      setBounties(
        bountyData
          ?.map((bounty) => {
            let creator = allUsersData.filter((goatcoinUser) => goatcoinUser.id === bounty.createdBy);
            creator = creator.length === 1 && creator[0];
            return {
              ...bounty,
              creator,
              value: bounty.donations.reduce((total, donation) => donation.amount + total, 0),
            };
          })
          .sort((a, b) => (a.value < b.value ? 1 : -1)),
      );
    }
  }, [allUsersData, bountyData]);

  const handleCloseCreate = useCallback(() => {
    setCreateIsOpen(false);
  }, []);
  const handleCloseDonate = useCallback(() => {
    setDonateIsOpen(false);
  }, []);
  const handleCloseComplete = useCallback(() => {
    setCompleteIsOpen(false);
  }, []);

  const createBounty = useCallback(
    async (formVals) => {
      setCreateIsOpen(false);
      await createBountyRequest({
        requestBody: {
          title: formVals.title,
          ticketNumber: formVals.jiraTicket,
          description: formVals.description,
          amount: parseInt(formVals.bountyValue),
        },
      });
      getBounties({});
      getUser({});
    },
    [createBountyRequest, getBounties, getUser],
  );
  const donateBounty = useCallback(
    async (formVals) => {
      setDonateIsOpen(false);
      await donateBountyRequest({
        url: `/bounty/${selectedBounty?.ID}/donate`,
        requestBody: { amount: parseInt(formVals.donation) },
      });
      getBounties({});
      getUser({});
    },
    [donateBountyRequest, getBounties, getUser, selectedBounty],
  );
  const completeBounty = useCallback(
    async (formVals) => {
      const completeRequests = formVals.amounts.map((amount, index) => {
        return { amount: parseInt(amount), userId: formVals.users[index].id };
      });

      setCompleteIsOpen(false);
      await completeBountyRequest({ url: `/bounty/${selectedBounty?.ID}/complete`, requestBody: completeRequests });
      getBounties({});
      getUser({});
    },
    [completeBountyRequest, getBounties, getUser, selectedBounty],
  );

  const openCompleteForm = (bounty) => {
    setSelectedBounty(bounty);
    setCompleteIsOpen(true);
  };
  const openDonateForm = (bounty) => {
    setSelectedBounty(bounty);
    setDonateIsOpen(true);
  };
  const openCreateForm = () => {
    setCreateIsOpen(true);
  };

  return (
    <Container maxWidth='md'>
      <Alert className={globalStyles['mt-2']} show={getBountiesError} severity='error'>
        Error retrieving Bounties.
      </Alert>
      <Alert className={globalStyles['mt-2']} show={userError || allUsersError} severity='error'>
        Error retrieving user data.
      </Alert>
      <Alert className={globalStyles['mt-2']} show={createBountyError} severity='error'>
        Error creating bounty.
      </Alert>
      <Alert className={globalStyles['mt-2']} show={donateBountyError} severity='error'>
        Error donating to bounty.
      </Alert>
      <Alert className={globalStyles['mt-2']} show={completeBountyError} severity='error'>
        Error completing bounty.
      </Alert>
      <Paper className={clsx(globalStyles.paper, classes.infoPaper)}>
        <Typography component={'p'}>
          Bounties are user created and funded tasks that reward those who complete them with goatcoin!
        </Typography>
        <Box mt={1}>
          <Typography component={'p'}>
            If you see an existing bounty you would also like completed you can add your goatcoin to the bounty!
          </Typography>
        </Box>
        <Box mt={1}>
          <Typography component={'p'}>
            If you want to work on a bounty take a look at the associated Jira ticket and let the bounty creator know
            when it is done so they can mark it completed and you can get paid the bounty!
          </Typography>
        </Box>
      </Paper>
      <Loader
        loading={
          loadingBounties || loadingUser || loadingAllUsers || creatingBounty || donatingBounty || completingBounty
        }
        size={100}
        color={theme.palette.primary.main}>
        <BountyCards
          bounties={bounties}
          complete={openCompleteForm}
          donate={openDonateForm}
          create={openCreateForm}
          user={userData && userData[0]}
        />
        <FormModal
          FormComponent={CreateBountyForm}
          isOpen={createIsOpen}
          handleClose={handleCloseCreate}
          onSubmit={createBounty}
          submitText='Create'
          formProps={{ allowance: userData && userData.length === 1 ? userData[0].account.allowance : 0 }}
        />
        <FormModal
          FormComponent={DonateBountyForm}
          isOpen={donateIsOpen}
          handleClose={handleCloseDonate}
          onSubmit={donateBounty}
          submitText='Donate'
          formProps={{
            bountyTitle: selectedBounty?.title,
            allowance: userData && userData.length === 1 ? userData[0].account.allowance : 0,
          }}
        />

        <FormModal
          FormComponent={CompleteBountyForm}
          isOpen={completeIsOpen}
          handleClose={handleCloseComplete}
          onSubmit={completeBounty}
          submitText='Complete'
          formProps={{
            userData: userData && userData[0],
            bountyValue: selectedBounty?.value,
            bountyTitle: selectedBounty?.title,
            users: allUsersData?.sort((a, b) => (a?.name?.toLowerCase() > b?.name?.toLowerCase() ? 1 : -1)),
          }}
        />
      </Loader>
    </Container>
  );
};

export default BountiesPage;
