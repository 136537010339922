import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import Loader from '../components/loader';

const ProtectedRoute = ({ render: C, ...routeProps }) => {
  const { oktaAuth, authState } = useOktaAuth();

  useEffect(() => {
    if (!authState) {
      return;
    }

    if (!authState?.isAuthenticated) {
      const originalUri = toRelativeUrl(window.location.href, window.location.origin);
      oktaAuth.setOriginalUri(originalUri);
      oktaAuth.signInWithRedirect();
    }
  }, [oktaAuth, authState?.isAuthenticated, authState]);

  if (!authState?.isAuthenticated) {
    return <Loader loading={true} />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
