import React, { useCallback, useEffect, useState } from 'react';
import { AppBar, Link, makeStyles, Toolbar, Avatar } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { NavLink } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import logo from '../../assets/logo.png';
import { useOktaAuth } from '@okta/okta-react';
import Loader from '../loader';

const useLocalStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: 'rgb(244, 246, 249)',
  },
  title: {
    flexGrow: 1,
  },
  activeLink: {
    color: theme.palette.primary.main,
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    padding: '5px 10px 5px 10px',
    textDecoration: 'none',
  },
  inactiveLink: {
    color: theme.palette.primary.main,
    padding: '5px 10px 5px 10px',
    textDecoration: 'none',
  },
  headerLink: {
    color: theme.palette.primary.main,
    padding: '5px 10px 7px 10px',
    cursor: 'pointer',
  },
}));

const Index = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const [loading, setLoading] = useState(false);
  const classes = useLocalStyles();
  const [userName, setUserName] = useState('');

  const logout = useCallback(async () => {
    setLoading(true);
    await oktaAuth.signOut();
    setLoading(false);
  }, [oktaAuth]);

  const getIntitials = () => {
    return userName
      ?.split(' ')
      .map((name) => name[0])
      .join('');
  };

  useEffect(() => {
    if (!authState?.isAuthenticated) return;

    setUserName(authState?.idToken?.claims?.name);
  }, [authState?.idToken?.claims?.name, authState?.isAuthenticated]);

  return (
    <AppBar position='static' className={classes.appBar}>
      <Toolbar>
        <div className={classes.title}>
          <RouterLink to='/'>
            <img src={logo} alt='Everquote logo' width='160' />
          </RouterLink>
        </div>

        <Box className={classes.headerLink}>
          <NavLink to={'/'} className={({ isActive }) => (isActive ? classes.activeLink : classes.inactiveLink)}>
            Home
          </NavLink>
        </Box>
        <Box className={classes.headerLink}>
          <NavLink to={'/give'} className={({ isActive }) => (isActive ? classes.activeLink : classes.inactiveLink)}>
            Give
          </NavLink>
        </Box>
        <Box className={classes.headerLink}>
          <NavLink
            to={'/bounties'}
            className={({ isActive }) => (isActive ? classes.activeLink : classes.inactiveLink)}>
            Bounties
          </NavLink>
        </Box>
        <Box className={classes.headerLink}>
          <NavLink to={'/redeem'} className={({ isActive }) => (isActive ? classes.activeLink : classes.inactiveLink)}>
            Redeem
          </NavLink>
        </Box>

        {authState?.isAuthenticated && (
          <Loader loading={loading}>
            <Link className={classes.headerLink} onClick={logout}>
              Logout
            </Link>
          </Loader>
        )}

        {authState?.isAuthenticated && <Avatar alt={userName}>{getIntitials()}</Avatar>}
      </Toolbar>
    </AppBar>
  );
};

export default Index;
