import React from 'react';
import Routes from '../routes';
import { BrowserRouter as Router } from 'react-router-dom';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Header from '../components/header';
import OktaAuthComponent from '../components/oktaAuth';

const palette = {
  primary: { main: '#205BB9' },
  secondary: { main: '#ed7119' },
};

const theme = createTheme({ palette });

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <OktaAuthComponent>
          <Header />
          <Routes />
        </OktaAuthComponent>
      </Router>
    </ThemeProvider>
  );
}

export default App;
