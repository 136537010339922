import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { getEnvVariable } from '../../utils/envUtils';

let tokenManager;

const oktaConfig = {
  clientId: getEnvVariable('clientId'),
  issuer: getEnvVariable('issuer'),
  redirectUri: `${window.location.origin}/login/callback`,
  scopes: [],
  pkce: true,
  transformAuthState: async (_, authState) => {
    authState.isAuthenticated = !!authState.accessToken && !tokenManager.hasExpired(authState.accessToken);
    return authState;
  },
};

const oktaAuth = new OktaAuth(oktaConfig);
tokenManager = oktaAuth.tokenManager;

const setIdToken = async (_oktaAuth) => {
  const existingIdToken = await _oktaAuth.tokenManager.get('idToken');
  if (existingIdToken) {
    console.log('idToken already exists, skipping fetch');
    return;
  }

  const originalScopes = _oktaAuth.options.scopes;
  try {
    _oktaAuth.options = { ..._oktaAuth.options, scopes: ['openid', 'profile', 'email'] };
    const tokensResp = await _oktaAuth.token.getWithRedirect();

    if (tokensResp?.tokens?.idToken) {
      _oktaAuth.tokenManager.add('idToken', tokensResp.tokens.idToken);
    }
  } catch (error) {
    console.error('Error fetching idToken:', error);
  } finally {
    _oktaAuth.options = { ..._oktaAuth.options, scopes: originalScopes };
  }
};

const OktaAuthComponent = ({ children }) => {
  const navigate = useNavigate();

  const restoreOriginalUri = useCallback(
    async (_oktaAuth, originalUri) => {
      await setIdToken(_oktaAuth);

      navigate(toRelativeUrl(originalUri, window.location.origin), { replace: true });
    },
    [navigate],
  );
  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      {children}
    </Security>
  );
};

export default OktaAuthComponent;
