export const ENVIRONMENT_VARIABLES = {
  apiGateway: {
    local: 'http://localhost:3000/dev',
    staging: 'https://ttv8g9uacd.execute-api.us-east-1.amazonaws.com/staging',
    prod: 'https://6r5a6padpb.execute-api.us-east-1.amazonaws.com/prod',
  },
  clientId: {
    local: '0oa1z7o7ok7R8bDcx0h8',
    staging: '0oa1z7o7ok7R8bDcx0h8',
    prod: '0oaqpxqidsPD8l4DZ4x7',
  },
  issuer: {
    local: 'https://everquote.oktapreview.com/oauth2/aus2baqkx8lsWjaHY0h8',
    staging: 'https://everquote.oktapreview.com/oauth2/aus2baqkx8lsWjaHY0h8',
    prod: 'https://everquote.okta.com/oauth2/ausqpy659qNymcZwn4x7',
  },
};

export const ENVIRONMENTS = {
  LOCAL: 'LOCAL',
  STAGING: 'STAGING',
  PROD: 'PROD',
};

export const HTTP_ACTION = {
  GET: 'GET',
  POST: 'POST',
};
